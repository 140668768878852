import IRoute from "../interfaces/route.interface";
import SignUpPage from "../pages/auth/SignUpPage";
import SolutionsPage from "../pages/SolutionsListPage";
import { SolutionSheetNewPage, SolutionSheetEditPage } from "../pages/EditSolutionSheet";
import HomePage from "../pages/HomePage";
import WorkSheetsPage from "../pages/WorkSheetsPage";
import { WorkSheetNewPage } from "../pages/WorkSheetNewPage";
import { WorkSheetPage } from "../pages/WorksheetPage";

export const Paths = {
    HOME: '/',
    SHEETADD: '/sheet/add',
    SHEETEDIT: '/sheet/edit/:id',
    WORKSHEETS: '/worksheets',
    WORKSHEET: '/worksheet/:id',
    WORKSHEETS_NEW: '/worksheet/new',
    SOLUTIONSHEETS: '/cart',
    SIGNUP: '/auth/signup',
}

const routes: IRoute[] = [
    {
        path: Paths.HOME,
        exact: true,
        component: HomePage,
        name: 'Home Page',
        protected: true
    },
    {
        path: Paths.SHEETADD,
        exact: true,
        component: SolutionSheetNewPage,
        name: 'New Solution Sheet',
        protected: true
    },
    {
        path: Paths.WORKSHEETS_NEW,
        exact: true,
        component: WorkSheetNewPage,
        name: 'New Work Sheet',
        protected: true
    },
    {
        path: Paths.WORKSHEET,
        exact: true,
        component: WorkSheetPage,
        name: 'Work Sheet',
        protected: true
    },
    {
        path: Paths.SHEETEDIT,
        exact: true,
        component: SolutionSheetEditPage,
        name: 'Edit Solution Sheet',
        protected: true
    },
    {
        path: Paths.SOLUTIONSHEETS,
        exact: true,
        component: SolutionsPage,
        name: 'Solution Sheets',
        protected: true
    },
    {
        path: Paths.WORKSHEETS,
        exact: true,
        component: WorkSheetsPage,
        name: 'Work Sheets',
        protected: true
    },
    {
        path: Paths.SIGNUP,
        exact: true,
        component: SignUpPage,
        name: 'Login Page',
        protected: false
    },
];

export default routes;