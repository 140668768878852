import React from "react";
import { useHistory, useParams } from "react-router-dom";

import { SolutionSheet } from "../interfaces/SolutionSheet";
import { Paths } from "../config/routes";
import {
  createSolutionSheet,
  updateSolutionSheet,
} from "../modules/solution-service";
import { SolutionSheetEditComponent } from "./SolutionSheetEditComponent";
import { useRecoilValue } from "recoil";
import { SolutionSheetsState } from "../AppInitialState";

export const SolutionSheetNewPage: React.FunctionComponent = () => {
  const history = useHistory();
  return (
    <div>
      <h1>Create Solutions Sheet</h1>
      <SolutionSheetEditComponent
        doneEdit={(data: SolutionSheet) => {
          createSolutionSheet(data);
          history.push(Paths.SOLUTIONSHEETS);
        }}
      ></SolutionSheetEditComponent>
    </div>
  );
};

type QuizParams = {
  id: string;
};

export const SolutionSheetEditPage: React.FunctionComponent = () => {
  const { id } = useParams<QuizParams>();
  const solutionSheets = useRecoilValue(SolutionSheetsState);
  const history = useHistory();
  const solutionSheet = solutionSheets.find((s) => s.id === id);

  if (!solutionSheet) {
    return <div>loading</div>;
  }

  return (
    <div>
      <h1>Edit Solution Sheet</h1>
      <SolutionSheetEditComponent
        solutionSheet={solutionSheet}
        doneEdit={(data: SolutionSheet) => {
          updateSolutionSheet(id, data);
          history.push(Paths.SOLUTIONSHEETS);
        }}
      ></SolutionSheetEditComponent>
    </div>
  );
};
