export enum AnswerType {
    string = "String",
    list = "List",
    multipleChoice = "MultipleChoice",
    singleChoice = "SingleChoice",
};

export interface SolutionLine {
    answer: string,
    type: AnswerType,
    possilbeAnswers?: string[];
}

export interface SolutionSheet {
    name: string,
    published: boolean,
    id?: string,
    solutions?: SolutionLine[];
};

export interface AnswerLine {
    answer: string,
    time: number,
    quesitonIndex: number,
}

export interface WorkSheet {
    id?: string,
    solution: SolutionSheet,
    owner?: string,
    answers?: AnswerLine[];
};

const equalsIgnoreOrder = (a: string[], b: string[]) => {
    a.sort();
    b.sort();
    return a.length === b.length &&
        a.every((v, i) => v.trim() === b[i].trim());
}

export function isAnswerCorrect(answer: AnswerLine, solution: SolutionLine): boolean {
    if (solution.type === AnswerType.string)
        return answer.answer === solution.answer;
    if (solution.type === AnswerType.list) {
        return equalsIgnoreOrder(
            answer.answer.split(","),
            solution.answer.split(",")
        )
    }
    return false;
}