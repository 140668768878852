import React, { Fragment } from "react";
import { atom, selector, useSetRecoilState } from "recoil";
import { getSolutionSheetsStream } from "./modules/solution-service";
import { SolutionSheet, WorkSheet } from "./interfaces/SolutionSheet";
import { getWorkSheetsStream } from "./modules/worksheet-service";

export const DefaultNullUser = { name: "", email: "", userId: "" };
export const AppCurrentUser = atom({
  key: "AppCurrentUser",
  default: DefaultNullUser,
});

// this can't be a selector because the state of this mutates on its own.
// from streaming updates. 
export const SolutionSheetsState = atom({
  key: "SolutionSheetState",
  default: [] as SolutionSheet[], // default value (aka initial value)
});

export const StateSolutionSheetsPublished = selector({
  key: "PublishedSolutionSheetState",
  get: ({ get }) => {
    const text = get(SolutionSheetsState);
    return text.filter((solution) => solution.published);
  },
});

export const WorkSheetsState = atom({
  key: "WorkSheetState",
  default: [] as WorkSheet[], // default value (aka initial value)
});

export const RecoilInitialStateComponent: React.FunctionComponent = (props) => {
  const setterSolutions = useSetRecoilState(SolutionSheetsState);
  const setterWorksheets = useSetRecoilState(WorkSheetsState);
  getSolutionSheetsStream((data) => {
    setterSolutions(data);
  });
  getWorkSheetsStream((data) => {
    setterWorksheets(data);
  });
  return <Fragment></Fragment>;
};