import firebase from 'firebase/app';
import { firestore } from '../../config/firebase'
import { MyUser } from '../../interfaces/MyUser';
// https://medium.com/swlh/using-firestore-with-typescript-65bd2a602945
// https://gist.github.com/JamieCurnow/cba3968a7f1e335d473632f9fc9f6e8b
//
// Import or define your types
// import { YourType } from '~/@types'

import { SolutionSheet, WorkSheet } from '../../interfaces/SolutionSheet';

const converter = <T>() => ({
    toFirestore: (data: Partial<T>) => data,
    fromFirestore: (snap: firebase.firestore.QueryDocumentSnapshot) => snap.data() as T
})

const dataPoint = <T>(collectionPath: string) => firestore.collection(collectionPath).withConverter(converter<T>())

const db = {
    subscriptions: dataPoint<SolutionSheet>('subscriptions'),
    worksheets: dataPoint<WorkSheet>('worksheets'),
    user: (userId: string) => dataPoint<MyUser>(`users/${userId}`)
}

export { db }