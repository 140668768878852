// auth-service.jsx
import { db } from "./db";
import { WorkSheet } from "../interfaces/SolutionSheet";
import { auth } from "../config/firebase";


export const getWorkSheets = async () => {
    const querySnapshot = await db.worksheets.get();
    var result: WorkSheet[] = [];
    querySnapshot.forEach((doc) => {
        const data = doc.data();
        data.id = doc.id;
        result.push(data);
    });
    return result;
};

export const getWorkSheetsStream = (
    callback: (data: WorkSheet[]) => void
) => {
    db.worksheets
        .where("owner", "==", auth.currentUser!.uid)
        .onSnapshot((obs) => {
        var result: WorkSheet[] = [];
        obs.forEach((doc) => {
            const data = doc.data();
            data.id = doc.id;
            result.push(data);
        });
        callback(result);
    });
};

export const createWorkSheet = async (sheet: WorkSheet) => {
    sheet.owner = auth.currentUser!.uid;
    return db.worksheets.add(sheet);
};

export const updateWorkSheet = async (
    sheet_id: string,
    sheet: WorkSheet
) => {
    return db.worksheets.doc(sheet_id).set(sheet);
};

export const getWorkSheetByID = async (sheet_id: string) => {
    return (await db.worksheets.doc(sheet_id).get()).data();
};

export const deleteWorkSheetByID = async (sheet_id: string) => {
    return db.worksheets.doc(sheet_id).delete();
};