import React, { ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import { useRecoilValue } from "recoil";
import { AppCurrentUser } from "../../AppInitialState";
import { auth } from "../../config/firebase";
import LoginHeader from "../../pages/LoginHeader";

interface IAuthRouteProps {
  children: ReactNode;
  // any other props that come into the component
}

const AuthRoute: React.FunctionComponent<IAuthRouteProps> = (props) => {
  const { children } = props;
  const currentUser = useRecoilValue(AppCurrentUser);

  const authenticated = currentUser.userId !== "";

  // const [authenticated, setAuthenticated] = React.useState(
  //   auth.currentUser != null
  // );

  if (!authenticated) {
    return <Redirect to="/auth/signup" />;
  }

  // auth.onAuthStateChanged((user) => {
  //   if (!user) {
  //     setAuthenticated(false);
  //   }
  // });
  return (
    <div>
      <LoginHeader />
      {children}
    </div>
  );
};

export default AuthRoute;