// import { useHistory } from "react-router-dom";

import React from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { useRecoilValue } from "recoil";
import { StateSolutionSheetsPublished } from "../AppInitialState";
import { Paths } from "../config/routes";
import { WorkSheet } from "../interfaces/SolutionSheet";
import { createWorkSheet } from "../modules/worksheet-service";

type MyOptionType = { label: string; value: number };

export const WorkSheetNewPage: React.FunctionComponent = () => {
  const solutionSheets = useRecoilValue(StateSolutionSheetsPublished);
  const history = useHistory();
  const options = solutionSheets.map((solution, i) => {
    return {
      label: solution.name,
      value: i,
    };
  });
  const selectChange = (a: any, b: any) => {
    const option = a as MyOptionType;
    const selectSolutionSheet = solutionSheets[option.value];
    const worksheet: WorkSheet = {
      solution: selectSolutionSheet,
    };
    createWorkSheet(worksheet);
    history.push(Paths.WORKSHEETS);
  };

  return (
    <div>
      <h4>Select a Work Sheet to work on </h4>
      <Select options={options} onChange={selectChange} />
    </div>
  );
};
