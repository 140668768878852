import { Button } from "@material-ui/core";
import React from "react";
import { useRecoilValue } from "recoil";
import { AppCurrentUser } from "../AppInitialState";
import { SignOut } from "../modules/auth";
import { AppHeader } from "../styles/HomeStyles";

const LoginHeader: React.FunctionComponent = (props) => {
  const AppUser = useRecoilValue(AppCurrentUser);
  return (
    <AppHeader>
      <b>Worksheet App</b>
      {AppUser.name}
      <Button onClick={SignOut}>Logout</Button>
    </AppHeader>
  );
};

export default LoginHeader;
