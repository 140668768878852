// auth-service.jsx
import { SolutionSheet } from "../interfaces/SolutionSheet";
import { db } from "./db";

export const getSolutionSheets = async () => {
  const querySnapshot = await db.subscriptions.get();
  var result: SolutionSheet[] = [];
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    data.id = doc.id;
    result.push(data);
  });
  return result;
};

export const getSolutionSheetsStream = (
  callback: (data: SolutionSheet[]) => void
) => {
  db.subscriptions.onSnapshot((obs) => {
    var result: SolutionSheet[] = [];
    obs.forEach((doc) => {
      const data = doc.data();
      data.id = doc.id;
      result.push(data);
    });
    callback(result);
  });
};

export const createSolutionSheet = async (sheet: SolutionSheet) => {
  await db.subscriptions.add(sheet);
};

export const updateSolutionSheet = async (
  sheet_id: string,
  sheet: SolutionSheet
) => {
  return db.subscriptions.doc(sheet_id).set(sheet);
};

export const getSolutionSheetByID = async (sheet_id: string) => {
  return (await db.subscriptions.doc(sheet_id).get()).data();
};

export const deleteSolutionSheetByID = async (sheet_id: string) => {
  return db.subscriptions.doc(sheet_id).delete();
};
