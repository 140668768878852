// import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { isAnswerCorrect, WorkSheet } from "../interfaces/SolutionSheet";
import {
  getWorkSheetByID,
  updateWorkSheet,
} from "../modules/worksheet-service";
import { ListItem, Para } from "../styles/HomeStyles";

interface IAnswerComponentProp {
  answerIndex: number;
  submit: (answer: string, index: number) => void;
}

const AnswerComponent: React.FunctionComponent<IAnswerComponentProp> = ({
  answerIndex,
  submit,
}) => {
  const [text, setText] = useState("");
  return (
    <ListItem>
      Answer
      <input
        value={text}
        onChange={(e) => {
          setText(e.target.value);
        }}
      />
      <button
        onClick={() => {
          if (text.trim().length > 0) {
            submit(text.trim(), answerIndex);
            setText("");
          }
        }}
      >
        Submit Problem {answerIndex + 1}
      </button>
    </ListItem>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  correct: {
    color: "green",
  },
  wrong: {
    color: "red",
  },
}));

export const WorkSheetPage: React.FunctionComponent = () => {
  const classes = useStyles();

  const { id } = useParams<{ id: string }>();
  const [worksheet, setWorksheet] = useState<WorkSheet | undefined>(undefined);

  useEffect(() => {
    getWorkSheetByID(id).then((sheet) => {
      setWorksheet(sheet);
    });
  }, [id]);

  if (!worksheet) return <div>Loading</div>;
  const data = worksheet?.solution.solutions!.map((sol, index) => {
    return {
      solution: sol,
      index: index,
      answers: worksheet?.answers?.filter((a) => a.quesitonIndex === index),
    };
  });

  return (
    <div>
      <Link to={`/`}>
        <button>Back Home</button>
      </Link>
      <Para>
        <h4>Work Sheet</h4>
        <h4>{worksheet.solution.name}</h4>
      </Para>
      {data!.map((question, i) => {
        return (
          <div key={i}>
            <Para>Problem: {i + 1} </Para>
            {question.answers?.map((attempt, k) => (
              <Para
                key={`key${k}`}
                className={
                  isAnswerCorrect(attempt, question.solution)
                    ? classes.correct
                    : classes.wrong
                }
              >
                {attempt.answer}
                {isAnswerCorrect(attempt, question.solution) ? (
                  <span>Correct!</span>
                ) : null}
                ( attempted on {dayjs.unix(attempt.time).format("M/DD h:mmA")})
              </Para>
            ))}
            <AnswerComponent
              answerIndex={i}
              submit={(answer: string, index: number) => {
                if (!worksheet.answers) {
                  worksheet.answers = [];
                }
                worksheet.answers.push({
                  quesitonIndex: index,
                  answer: answer,
                  time: dayjs().unix(),
                });
                const newsheet = JSON.parse(JSON.stringify(worksheet));
                setWorksheet(newsheet);
                updateWorkSheet(id, newsheet);
              }}
            ></AnswerComponent>
          </div>
        );
      })}
    </div>
  );
};