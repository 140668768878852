import React from "react";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { SolutionSheetsState } from "../AppInitialState";
import { Paths } from "../config/routes";
import IPageProps from "../interfaces/page.interface";
import { deleteSolutionSheetByID } from "../modules/solution-service";
import { ListItem } from "../styles/HomeStyles";
const SolutionsPage: React.FunctionComponent<IPageProps> = (props) => {
  const solutionSheets = useRecoilValue(SolutionSheetsState);
  return (
    <div>
      <Link to={Paths.SHEETADD}>
        <button>New Solution Sheet</button>
      </Link>
      <p></p>
      {solutionSheets.map((solution, i) => (
        <ListItem>
          <Link to={`/sheet/edit/${solution.id}`}>{solution.name}</Link>
          <button
            onClick={() => {
              deleteSolutionSheetByID(solution.id!);
            }}
          >
            Delete
          </button>
        </ListItem>
      ))}
    </div>
  );
};

export default SolutionsPage;
