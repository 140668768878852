import React from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { auth } from "../config/firebase";
import IPageProps from "../interfaces/page.interface";
import SolutionsPage from "./SolutionsListPage";
import WorkSheetsPage from "./WorkSheetsPage";

const HomePage: React.FunctionComponent<IPageProps> = (props) => {
  return (
    <div>
      <Tabs>
        <TabList>
          <Tab>Work Sheets</Tab>
          {auth.currentUser?.email === "xhuang@gmail.com" ? (
            <Tab>Solutions</Tab>
          ) : null}
        </TabList>
        <TabPanel>
          <WorkSheetsPage name="worksheet"></WorkSheetsPage>
        </TabPanel>
        <TabPanel>
          <SolutionsPage name="hey"></SolutionsPage>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default HomePage;
