import deepcopy from "deepcopy";
import React, { useState } from "react";
import Select from "react-select";
import {
  SolutionLine,
  SolutionSheet,
  AnswerType,
} from "../interfaces/SolutionSheet";

interface ISolutionSheetEditProp {
  solutionSheet?: SolutionSheet;
  doneEdit: (solution: SolutionSheet) => void;
}

interface RowParams {
  solution: SolutionLine;
  valueChanged: (newvalue: SolutionLine) => void;
}

const RowEdit: React.FunctionComponent<RowParams> = ({
  solution,
  valueChanged,
}) => {
  return (
    <span>
      <input
        value={solution.answer}
        onChange={(e) => {
          solution.answer = e.target.value;
          valueChanged(solution);
        }}
      />
      <Select
        options={[
          { label: "String", value: AnswerType.string },
          { label: "List", value: AnswerType.list },
        ]}
        value={{
          label: solution.type as string,
          value: solution.type,
        }}
        onChange={(e) => {
          solution.type = e?.value!;
          valueChanged(solution);
        }}
      />
    </span>
  );
};

export const SolutionSheetEditComponent: React.FunctionComponent<ISolutionSheetEditProp> =
  ({ solutionSheet, doneEdit }) => {
    const [name, setName] = useState(solutionSheet?.name || "");
    const [published, setPublished] = useState(
      solutionSheet?.published || false
    );

    const [solutions, setSolutions] = React.useState(
      solutionSheet?.solutions || ([] as SolutionLine[])
    );

    const solutionsCopy = deepcopy(solutions);

    const addInput = () => {
      setSolutions([
        ...solutionsCopy,
        {
          answer: "",
          type: AnswerType.string,
        },
      ]);
    };

    const deleteInput = (i: number) => {
      solutionsCopy.splice(i, 1);
      setSolutions(solutionsCopy);
    };

    const saveAll = () => {
      doneEdit({
        name: name,
        published: published,
        solutions: solutionsCopy,
      });
    };

    return (
      <div>
        <div>Solution Sheet Name</div>
        <input
          key={"name"}
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />

        <div>
          Published
          <input
            type="checkbox"
            checked={published}
            onChange={(e) => {
              setPublished(e.target.checked);
            }}
          />
        </div>
        <div>
          List of Solutions
          <button onClick={addInput}>New Solution Line</button>
        </div>
        <ol>
          {solutionsCopy.map((n, i) => {
            return (
              <li key={i}>
                <RowEdit
                  solution={deepcopy(n)}
                  valueChanged={(v) => {
                    solutionsCopy[i] = v;
                    setSolutions([...solutionsCopy]);
                  }}
                />
                <button
                  onClick={() => {
                    deleteInput(i);
                  }}
                >
                  delete
                </button>
              </li>
            );
          })}
        </ol>
        <button onClick={saveAll}>Save Sheet</button>
      </div>
    );
  };
